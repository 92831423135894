import { types } from "@halter-corp/halter-common-login";

export const awsAuthConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
};

export const environment =
  (process.env.REACT_APP_ENVIRONMENT as types.Environment) || undefined;

export const defaultReferral = process.env.REACT_APP_DEFAULT_REFERRAL || "";
