import { defaultReferral } from "../../env-exports";
import { GetQuery } from "./getQuery";
import { normalizeHttpsUrl } from "./normalizeHttpsUrl";

export const RedirectToReferrer = () => {
  const result = GetQuery().get("referral") ?? defaultReferral;

  if (result) {
    window.location.assign(normalizeHttpsUrl(result));
  }
};

export default RedirectToReferrer;
