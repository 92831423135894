import { Auth } from "aws-amplify";
import { Auth as HalterAuth } from "@halter-corp/halter-common-login";

import { environment } from "../../env-exports";
import { ResetPasswordData } from "../recoverAccount/RecoverAccount";

export class NotAuthorizedException extends Error {}
export class UserNotFoundException extends Error {}
export class InvalidPasswordException extends Error {}
export class LimitExceededException extends Error {}
export class CodeMismatchException extends Error {}

export type UserCredentials = {
  email: string;
  password: string;
};

export type AuthResponse = {
  __type: string;
  message: string;
};

const AccountService = {
  login: async (credentials: UserCredentials): Promise<void> => {
    try {
      await Auth.signIn({
        username: credentials.email,
        password: credentials.password,
      });
    } catch (error) {
      throwParsedOrDefaultError(error);
    }
  },

  forgotPassword: async (email: string): Promise<void> => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      throwParsedOrDefaultError(error);
    }
  },

  forgotPasswordSubmit: async (
    resetPasswordData: ResetPasswordData
  ): Promise<void> => {
    try {
      await Auth.forgotPasswordSubmit(
        resetPasswordData.email!,
        resetPasswordData.verificationCode!,
        resetPasswordData.newPassword!
      );
    } catch (error) {
      throwParsedOrDefaultError(error);
    }
  },

  /**
   * Log the user out by 1) invalidating the refresh token on the AWS side and 2) deleting the session cookies on the client side
   */
  logout: async (): Promise<void> => {
    try {
      await Auth.signOut();
      HalterAuth.signOut(environment);
    } catch (error) {
      throwParsedOrDefaultError(error);
    }
  },
};

export interface CognitoException extends Error {
  code: string;
}

const isCognitoError = (e: any): e is CognitoException =>
  "code" in e && "message" in e;

export const throwParsedOrDefaultError = (error: any) => {
  if (isCognitoError(error)) {
    switch (error.code) {
      case "InvalidParameterException":
        throw new NotAuthorizedException();

      case "NotAuthorizedException":
        throw new NotAuthorizedException();

      case "UserNotFoundException":
        throw new UserNotFoundException();

      case "LimitExceededException":
        throw new LimitExceededException();

      case "CodeMismatchException":
        throw new CodeMismatchException();
    }
  }

  throw error;
};

export default AccountService;
