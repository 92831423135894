import React, { FunctionComponent } from "react";
import { Amplify } from "aws-amplify";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { cookieStorageConfig } from "@halter-corp/halter-common-login";

import { awsAuthConfig, environment } from "../env-exports";
import { Login } from "./login/Login";
import { Logout } from "./logout/Logout";
import RecoverAccount from "./recoverAccount/RecoverAccount";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    ...awsAuthConfig,
    cookieStorage: cookieStorageConfig({ env: environment }),
  },
});

const App: FunctionComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/recover-account" element={<RecoverAccount />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
