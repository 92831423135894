import React, { FunctionComponent } from "react";

import Button from "../../components/Button";
import { Step } from "../RecoverAccount";

type ResetSuccessProps = {
  changeStepCallback: (override?: Step) => void;
};

const ResetSuccess: FunctionComponent<ResetSuccessProps> = ({
  changeStepCallback,
}) => {
  return (
    <div className="flex flex-col w-full max-w-[327px] px-6 sm:w-96">
      <h1 className="text-black font-semibold text-2xl pb-4">
        {`You're all set.`}
      </h1>
      <p className="text-lg pb-2 text-brand-gray-blue">
        {`You've successfully changed your password.`}
      </p>
      <Button
        type="button"
        text="Sign in to Halter"
        onClick={changeStepCallback}
        className={
          "bg-brand-900 hover:bg-gray-800 text-white font-bold py-3 px-12 my-3 mt-12 rounded-md w-full"
        }
      ></Button>
    </div>
  );
};

export default ResetSuccess;
