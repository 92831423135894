import React, { FunctionComponent, useState } from "react";
import { FieldProps } from "formik";

import FieldButton from "../components/FieldButton";

type inputFieldProps = {
  type: string;
  labelText: string;
  placeholderText: string;
  className: string;
  autoFocus: boolean;
};

// Custom input field component - designed to work inside with Formik <Field /> which automatically handles onChange, errors etc...
const InputField: FunctionComponent<FieldProps & inputFieldProps> = ({
  field,
  form,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={props.className}>
      {props.labelText && (
        <label
          htmlFor={props.labelText}
          className="block font-semibold text-sm mb-1"
        >
          {props.labelText}
        </label>
      )}
      <div
        className={`flex flex-row rounded-lg h-12 px-4 text-gray-800 focus-within:border-brand-900 ${
          form.errors[field.name]
            ? "border-brand-red-600 border"
            : "border-gray border"
        } `}
      >
        <div className="flex flex-grow items-center w-full">
          <input
            autoFocus={props.autoFocus}
            id={field.name}
            placeholder={props.placeholderText}
            type={
              props.type === "password" && showPassword ? "text" : props.type
            }
            onChange={field.onChange}
            value={field.value}
            onFocus={() => setFocused(true)}
            onBlur={form.handleBlur}
            className="appearance-none w-full border-none focus: outline-none h-8"
          ></input>
        </div>
        {focused && field.value && (
          <FieldButton
            type={props.type}
            showPassword={showPassword}
            className="ml-4"
            onClick={() => {
              if (props.type === "password") {
                setShowPassword(!showPassword);
              } else {
                form.setFieldValue(field.name, "");
              }
            }}
          ></FieldButton>
        )}
      </div>

      {form.touched[field.name] && form.errors[field.name] && (
        <label className="text-brand-red-600 block text-sm leading-4 mt-2">
          {form.errors[field.name]}
        </label>
      )}
    </div>
  );
};

export default InputField;
