import React, { FunctionComponent } from "react";

import EyeHide from "../assets/eye_hide.svg";
import EyeShow from "../assets/eye_show.svg";
import ClearCircle from "../assets/clear_circle.svg";

type FieldButtonProps = {
  type: string;
  onClick: () => void;
  showPassword?: boolean;
  className?: string;
};

const FieldButton: FunctionComponent<FieldButtonProps> = ({
  type,
  onClick,
  showPassword,
  className,
}) => {
  return (
    <button onClick={onClick} type="button" className={className}>
      <img
        alt=""
        src={
          type === "password" ? (showPassword ? EyeHide : EyeShow) : ClearCircle
        }
      />
    </button>
  );
};

export default FieldButton;
