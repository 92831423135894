import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AccountService from "../accountService/account.service";
import Intermission from "../components/Intermission";
import { ANIMATION_RUN_TIME } from "../constants";

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signOut = async () => {
      await AccountService.logout();
    };
    signOut();
    setTimeout(() => {
      navigate("/" + window.location.search);
    }, ANIMATION_RUN_TIME);
  }, []);

  return <Intermission isFastAnimation />;
};
