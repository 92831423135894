import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

import FindAccount from "./steps/FindAccount";
import VerifyCode from "./steps/VerifyCode";
import ResetPassword from "./steps/ResetPassword";
import ResetSuccess from "./steps/ResetSuccess";

export type ResetPasswordData = {
  email?: string;
  verificationCode?: string;
  newPassword?: string;
};

export type RecoverAccountStepProps = {
  changeStepCallback: (override?: Step) => void;
  updateResetPasswordDataCallback: (data: ResetPasswordData) => void;
};

export type Step =
  | "FindAccount"
  | "VerifyCode"
  | "ResetPassword"
  | "ResetSuccess";

const RecoverAccount: FunctionComponent = () => {
  const [activeStep, setActiveStep] = useState<Step>("FindAccount");
  const [resetPasswordData, setResetPasswordData] =
    useState<ResetPasswordData>();
  const [verificationCodeIncorrect, setVerificationCodeIncorrect] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const renderStepContent = () => {
    switch (activeStep) {
      case "FindAccount":
        return (
          <FindAccount
            updateResetPasswordDataCallback={(data: ResetPasswordData) => {
              setResetPasswordData(prev => ({ ...prev, email: data.email }));
            }}
            changeStepCallback={() => {
              setActiveStep("VerifyCode");
            }}
          />
        );
      case "VerifyCode":
        return (
          <VerifyCode
            updateResetPasswordDataCallback={data => {
              setResetPasswordData(prev => ({
                ...prev,
                verificationCode: data.verificationCode,
              }));
            }}
            changeStepCallback={overrideStep => {
              setActiveStep(overrideStep ?? "ResetPassword");
            }}
            verificationCodeWasIncorrectCallback={(codeIncorrect: boolean) => {
              setVerificationCodeIncorrect(codeIncorrect);
            }}
            verificationCodeWasIncorrect={verificationCodeIncorrect}
          />
        );
      case "ResetPassword":
        return (
          <ResetPassword
            updateResetPasswordDataCallback={data =>
              setResetPasswordData(prev => ({
                ...prev,
                newPassword: data.newPassword,
              }))
            }
            changeStepCallback={() => {
              setActiveStep("ResetSuccess");
            }}
            verificationCodeWasIncorrectCallback={(codeIncorrect: boolean) => {
              setVerificationCodeIncorrect(codeIncorrect);
              setActiveStep("VerifyCode");
            }}
            email={resetPasswordData?.email || ""}
            verificationCode={resetPasswordData?.verificationCode || ""}
          />
        );
      case "ResetSuccess":
        return (
          <ResetSuccess
            changeStepCallback={() => navigate("/" + window.location.search)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full overflow-hidden flex flex-row items-center justify-center">
      {renderStepContent()}
    </div>
  );
};

export default RecoverAccount;
