import React, { FunctionComponent } from "react";
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "../components/Button";
import InputField from "../components/InputField";

import AccountService, {
  UserCredentials,
  NotAuthorizedException,
  UserNotFoundException,
} from "../accountService/account.service";

const LoginSchema: Yup.SchemaOf<UserCredentials> = Yup.object().shape({
  email: Yup.string().email().required("Email required"),
  password: Yup.string().required("Password required"),
});

type LoginFormProps = {
  onSuccess: () => void;
};
export const LoginForm: FunctionComponent<LoginFormProps> = ({ onSuccess }) => {
  const navigate = useNavigate();
  const initialValues: UserCredentials = { email: "", password: "" };

  const handleLogin = async (
    credentials: UserCredentials,
    setError: (field: string, message: string) => void
  ) => {
    try {
      await AccountService.login(credentials);
      onSuccess();
    } catch (error) {
      if (
        error instanceof NotAuthorizedException ||
        error instanceof UserNotFoundException
      ) {
        setError("password", "Incorrect email or password");
      } else {
        setError("password", "Something went wrong, please try again");
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setFieldError }) =>
        handleLogin(values, setFieldError)
      }
      validationSchema={LoginSchema}
    >
      {({ handleSubmit }) => (
        <form
          className="flex flex-col w-full items-center max-w-sm px-6 sm:w-96"
          onSubmit={handleSubmit}
        >
          <Field
            name="email"
            type="email"
            component={InputField}
            placeholderText="Email"
            labelText="Email"
            className="mb-8 w-full"
            autoFocus={true}
          />

          <Field
            name="password"
            type="password"
            component={InputField}
            placeholderText="Password"
            labelText="Password"
            className="mb-8 w-full"
          />
          <Button
            type="submit"
            text="Sign in"
            className={
              "bg-brand-900 hover:bg-gray-800 text-white font-bold py-3 px-12 my-3 mt-10 rounded-md w-full"
            }
          ></Button>
          <div className="sm: pb-5">
            <Button
              type="button"
              text="I forgot my password"
              onClick={() => {
                navigate("/recover-account" + window.location.search);
              }}
              className={"text-black font-bold border-b-2 border-black"}
            ></Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
