import React, { FunctionComponent } from "react";
import * as Yup from "yup";

import { Formik, Field } from "formik";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { RecoverAccountStepProps } from "../RecoverAccount";
import AccountService, {
  CodeMismatchException,
} from "../../accountService/account.service";

type ResetPasswordData = {
  newPassword: string;
  newPasswordConfirm: string;
};

type ResetPasswordStepProps = RecoverAccountStepProps & {
  email: string;
  verificationCode: string;
  verificationCodeWasIncorrectCallback: (codeWasIncorrect: boolean) => void;
};

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter"),

  newPasswordConfirm: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});

const ResetPassword: FunctionComponent<ResetPasswordStepProps> = ({
  changeStepCallback,
  updateResetPasswordDataCallback,
  verificationCodeWasIncorrectCallback,
  email,
  verificationCode,
}) => {
  const initialValues: ResetPasswordData = {
    newPassword: "",
    newPasswordConfirm: "",
  };

  const handleSubmit = async (
    formData: ResetPasswordData,
    setError: (field: string, message: string) => void
  ) => {
    try {
      await AccountService.forgotPasswordSubmit({
        email: email,
        verificationCode: verificationCode,
        newPassword: formData.newPassword,
      });
      changeStepCallback();
      updateResetPasswordDataCallback({
        newPassword: formData.newPassword,
      });
    } catch (error) {
      if (error instanceof CodeMismatchException) {
        verificationCodeWasIncorrectCallback(true);
      } else {
        setError(
          "newPasswordConfirm",
          "Something went wrong, please try again"
        );
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setFieldError }) => {
        handleSubmit(
          {
            newPassword: values.newPassword,
            newPasswordConfirm: values.newPasswordConfirm,
          },
          setFieldError
        );
      }}
      validationSchema={ResetPasswordSchema}
    >
      {(props) => (
        <form
          onSubmit={props.handleSubmit}
          className="flex flex-col w-full max-w-[327px] px-6 sm:w-96"
        >
          <h1 className="text-black font-semibold text-2xl pb-4">
            Reset your password
          </h1>
          <p className="text-lg pb-2 text-brand-gray-blue">
            Strong passwords include numbers, letters and punctuation marks.
            Resetting your password will sign you out of Halter.
          </p>

          <div className="space-y-4 mt-8">
            <Field
              name="newPassword"
              type="password"
              component={InputField}
              placeholderText="Enter your new password"
              autoFocus={true}
            ></Field>
            <Field
              name="newPasswordConfirm"
              type="password"
              component={InputField}
              placeholderText="Enter your new password one more time"
            ></Field>
          </div>

          <Button
            type="submit"
            text="Reset password"
            className={`${
              props.values.newPassword && props.values.newPasswordConfirm
                ? `bg-brand-900 hover:bg-gray-800`
                : `bg-brand-gray-blue-200`
            } text-white font-bold py-3 px-12 my-3 mt-12 rounded-md w-full`}
            disabled={
              props.values.newPassword.length <= 0 &&
              props.values.newPasswordConfirm.length <= 0
            }
          ></Button>
        </form>
      )}
    </Formik>
  );
};

export default ResetPassword;
