import React, { FunctionComponent } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { RecoverAccountStepProps } from "../RecoverAccount";
import AccountService, {
  LimitExceededException,
  UserNotFoundException,
} from "../../accountService/account.service";

type FindAccountFormData = {
  email: string;
};

const FindEmailSchema: Yup.SchemaOf<FindAccountFormData> = Yup.object().shape({
  email: Yup.string().email("Invalid email format.").required("Email required"),
});

const FindAccount: FunctionComponent<RecoverAccountStepProps> = ({
  changeStepCallback,
  updateResetPasswordDataCallback,
}) => {
  const initialValues: FindAccountFormData = { email: "" };

  const handleSubmit = async (
    formData: FindAccountFormData,
    setError: (field: string, message: string) => void
  ) => {
    try {
      await AccountService.forgotPassword(formData.email);

      changeStepCallback();
      updateResetPasswordDataCallback({
        email: formData.email,
      });
    } catch (error) {
      if (error instanceof UserNotFoundException) {
        setError("email", "User could not be found.");
      } else if (error instanceof LimitExceededException) {
        setError(
          "email",
          "Too many password reset attempts, please try again later."
        );
      } else {
        setError("email", "Something went wrong, please try again.");
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setFieldError }) => {
        handleSubmit({ email: values.email }, setFieldError);
      }}
      validationSchema={FindEmailSchema}
    >
      {(props) => (
        <form
          className="flex flex-col w-full max-w-[327px] px-6 sm:w-96"
          onSubmit={props.handleSubmit}
        >
          <h1 className="text-black font-semibold text-2xl pb-4">
            Find your Halter account
          </h1>
          <Field
            name="email"
            type="text"
            component={InputField}
            placeholderText="Email"
            autoFocus={true}
          ></Field>
          <p className="text-sm leading-4 pt-2 text-brand-gray-blue">
            Enter the email associated with your Halter account.
          </p>
          <Button
            type="submit"
            text="Search"
            className={`${
              props.values.email
                ? `bg-brand-900 hover:bg-gray-800`
                : `bg-brand-gray-blue-200`
            } text-white font-bold py-3 px-12 my-3 mt-12 rounded-md w-full`}
            disabled={props.values.email.length <= 0}
          ></Button>
        </form>
      )}
    </Formik>
  );
};

export default FindAccount;
