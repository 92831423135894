import React, { FunctionComponent, useState } from "react";

import { LoginForm } from "./LoginForm";
import Intermission from "../components/Intermission";
import RedirectToReferrer from "../redirect/RedirectToReferrer";
import { ANIMATION_RUN_TIME } from "../constants";
import { HalterLogoWordmark } from "../components/HalterLogoWordmark";

export const Login: FunctionComponent = () => {
  const [showIntermission, setShowIntermission] = useState<boolean>(false);

  const handleOnSuccess = () => {
    setShowIntermission(true);

    setTimeout(() => {
      RedirectToReferrer();
    }, ANIMATION_RUN_TIME);
  };

  return showIntermission ? (
    <Intermission />
  ) : (
    <div className="h-screen lg: grid grid-cols-7 ">
      <div
        className=" hidden lg:block col-span-4 bg-brand-900 bg-grow-more-grass bg-no-repeat bg-cover bg-center"
        style={{ backgroundPositionX: "30%" }}
      />
      <div className="col-span-7 flex flex-col items-center justify-center lg:col-span-3">
        <div className="text-brand-900 flex h-40 w-52 pb-20 pt-5">
          <HalterLogoWordmark />
        </div>
        <LoginForm onSuccess={handleOnSuccess} />
      </div>
    </div>
  );
};
