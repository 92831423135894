import React, { FunctionComponent } from "react";

type ButtonProps = {
  type: "button" | "submit" | "reset" | undefined;
  text: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const Button: FunctionComponent<ButtonProps> = ({
  type,
  text,
  onClick,
  className,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${!disabled || "cursor-not-allowed"} ${
        className ? className : "bg-gray-400 text-white"
      }`}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
