import React, { FunctionComponent } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { RecoverAccountStepProps } from "../RecoverAccount";

type VerifyCodeData = {
  code: string;
};

const VerifyCodeSchema: Yup.SchemaOf<VerifyCodeData> = Yup.object().shape({
  code: Yup.string().required("Verification code required."),
});

type VerifyCodeProps = RecoverAccountStepProps & {
  verificationCodeWasIncorrect: boolean;
  verificationCodeWasIncorrectCallback: (codeWasIncorrect: boolean) => void;
};

const VerifyCode: FunctionComponent<VerifyCodeProps> = ({
  verificationCodeWasIncorrect,
  changeStepCallback,
  updateResetPasswordDataCallback,
  verificationCodeWasIncorrectCallback,
}) => {
  const initialValues: VerifyCodeData = { code: "" };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        verificationCodeWasIncorrectCallback(false);
        changeStepCallback();
        updateResetPasswordDataCallback({
          verificationCode: values.code,
        });
      }}
      validationSchema={VerifyCodeSchema}
    >
      {(props) => (
        <form
          onSubmit={props.handleSubmit}
          className="flex flex-col w-full max-w-[327px] px-6 sm:w-96"
        >
          <h1 className="text-black font-semibold text-2xl pb-4">
            {verificationCodeWasIncorrect
              ? "Your code was incorrect"
              : "Check your email"}
          </h1>
          <p className="text-lg pb-2 text-brand-gray-blue">
            {verificationCodeWasIncorrect
              ? "The code you gave wasn't valid. Check your email for a valid code."
              : "You'll receive a code to verify here so you can reset your account password."}
          </p>

          <div className="max-w-52">
            <Button
              type="button"
              text="Didn't receive your code?"
              onClick={() => {
                changeStepCallback("FindAccount");
              }}
              className={
                "text-black font-semibold border-b-2 mb-12 border-black"
              }
            ></Button>
            <Field
              name="code"
              type="text"
              component={InputField}
              className="w-44"
              placeholderText="Code"
              autoFocus={true}
            ></Field>
          </div>

          <Button
            type="submit"
            text="Verify"
            className={`${
              props.values.code
                ? `bg-brand-900 hover:bg-gray-800`
                : `bg-brand-gray-blue-200`
            } text-white font-bold py-3 px-12 my-3 mt-12 rounded-md w-full`}
            disabled={props.values.code.length <= 0}
          ></Button>
        </form>
      )}
    </Formik>
  );
};

export default VerifyCode;
