import React from "react";
import Lottie from "lottie-react";

import halterLoadH from "../assets/halter-load-H.json";
import halterLoadHFast from "../assets/halter-load-H-1sec.json";

type IntermissionProps = {
  isFastAnimation?: boolean;
};

const Intermission = ({ isFastAnimation = false }: IntermissionProps) => {
  return (
    <div className="h-full overflow-hidden bg-brand-900">
      <div className="flex flex-row items-center justify-center h-screen">
        <div className="flex flex-row justify-between w-80 min-h-full">
          <Lottie
            animationData={isFastAnimation ? halterLoadHFast : halterLoadH}
          />
        </div>
      </div>
    </div>
  );
};

export default Intermission;
